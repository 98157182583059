.menu_toolbar {
    --background: #114b4a !important;
}

.menu_content{
    --background: #196b69;
}

.menu_list{
    --background: #196b69;
    margin: 0;
    padding: 0;
}

.menu_item{
    --background: #196b69;
}

.menu_item{
    color: white;
    height: 10%;
}

.menu_img_div{
    width: 100%;
    display: flex;
    padding-top: 40px;
}

.menu_img{
    margin: auto;
    width: 40%;
}