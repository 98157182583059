.footer {
  position: relative;
  width: 100vw;
  background-color: rgb(25,107,105);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease-out; /* Optional: Add transition for smooth appearance */
}

.link:hover {
  cursor: pointer
}

  
  .column {
    width: 40%; /* Adjust width as needed */
    display: inline-block;
    vertical-align: top;
  }

  .column3 {
    max-height: 20%;
    width: 10%; /* Adjust width as needed */
    display: inline-block;
    vertical-align: top;
  }

  .logo{
    display: block;
    width: 90% !important;
    height: auto !important;
    margin-right: 2%;
    filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.3));
}




  .column1 {
    width: 50%; /* Adjust width as needed */
    display: inline-block;
    vertical-align: top;
  }
  
  .column h4 {
    margin-top: 0;
  }
  
  .column ul {
    list-style: none;
    padding: 0;
  }
  
  .column ul li {
    margin-bottom: 10px;
  }
  
  .column ul li a {
    color: #4edda6;
    font-family: Arial, sans-serif;
    font-size: 14px;
    text-decoration: none;
  }

  .listpar{
    color: #4edda6;
    font-family: Arial, sans-serif;
    font-size: 14px;
    text-decoration: none;
  }

  .par{
    font-family: Arial, sans-serif;
    font-size: 15px;
    color: #ffffff;
  }

  .title{
    color: #ffffff;
  }


  @media(orientation: portrait){
    @media (max-width: 600px) {
      .column3 {
        height: 30%;
        width: 30%;
      }
      .column1 {
        width: 95%; /* Adjust width as needed */
        display: inline-block;
        vertical-align: top;
      }
    
    }
  }
