.mainTitle{
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: xx-large;
    font-weight: bold;
    color: #196b69;
    /* margin-bottom: 2vh; */
    margin-top: 5vh;

}





.titles{
    /* align-self: flex-start; */
    text-align: start;
    color: #196b69;
    margin-left: 2%;
    font-size: 2em;
    font-weight: bold;
    font-family: "Garamound", Times, serif;
    margin-top: 7%;
}

.premium h5{

    line-height: 1;
    margin-left: 20px;
    font-family: "Times New Roman", Times, serif;
}



.premium_header{
    height: 8%;
}

@media(orientation: landscape){
    .header{
      height: 15%;
    }
  }
  


.premium_background{
    
    /* background-image: linear-gradient(to top, #c1dfc4 0%, #deecdd 100%); */
    /* background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%); */
    background-image: linear-gradient(45deg, #93cfbd 0%, #e4efe9 100%);


    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(5px);
    z-index: -1;

    
}

.premium_background_img{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../resources/bg1.png');
    background-size: cover;
    filter: opacity(10%);
    z-index: -1;
}

.body-div{
    margin: auto;
    width: 80%;
    color: #196b69;
    text-align: start;
    display: flex;
    justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
    flex-direction: row;
    /* padding: auto; */
}

.plan_div{
    display: flex;
    /* max-width: 100vw; */
    width: 22vw;
    height: 70vh;    
    background-color: #196b69;
    border-radius: 10px;
    margin: 0px 20px 200px 20px;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.plan_name_div{
    /* margin-top: 8%; */
    padding-top: 8%;
    /* padding-bottom: 2%; */
    /* border-bottom: #93cfbd 3px solid; */
    color: #196b69;
    font-size: 2em;
    display: flex;
    justify-content: center;
    border-radius: 10px 10px 100px 100px;
    background-color: #28cc9e;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    transform-origin: top; /* Set the origin for rotation */
    transition: transform 0.5s ease-in-out;
}

.flap_background{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: red; */
    background-image: url('../resources/pbg3.jpg');
    background-size: cover;
    filter: opacity(10%);
    border-radius: 10px 10px 100px 100px;
    /* z-index: -1; */
    
}

.plan_div:hover .plan_name_div {
    transform: rotateX(-35deg); /* Rotate the flap to create a fold effect */
}

.plan_name{
    font-family: 'Cursive', sans-serif;
    margin: auto;
}

.plan_closed{
    font-size: 1.5em;
    margin: auto;
}

.plan_item{
    
    font-size: 1.1em;
    color: #e4efe9;
    margin: 40px 5px 20px 15px;
}

.plan_items{
    
    flex: 1;
}

.plan_price{
    margin: auto auto 0 auto;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    color: #e4efe9;
}

.price_value{
    font-size: 3em;
}

.segments{
    width: fit-content;
    margin: auto;
    margin-bottom: 6vh;
    
}

.segment_label{
    font-size: 15px;
}

.segment_btn{
    /* width: fit-content; */
}



.year_btn{
    --color: red !important;
}

@media only screen and (max-width: 700px) {
    .body-div{
        width: 90%;
    }
}


ion-segment-button::part(indicator-background) {
    background: #08a391;
  }

  /* Material Design styles */
ion-segment-button.md::part(native) {
    color: #000;
  }
  
  .segment-button-checked.md::part(native) {
    color: #08a391;
  }
  
  ion-segment-button.md::part(indicator-background) {
    height: 3px;
  }
  
  .paymentForm{
    padding: 0px;
    margin: auto;
    margin-top: 30px;
    padding-bottom: 5px;
    margin-bottom: 30px;
    width: 45%;
    background-color: #196b69 ;
    border-radius: 20px;
  }

  @media(orientation: portrait){
    .paymentForm{
        width: 100%;
      }
  }


  .plan_subscribe{
    --ion-color-secondary-contrast: #196b69;
    --background-color: #08a391;
    font-weight: 200px;
  }
  
@media(orientation: portrait){
    .segments{
        width: 55vw;
        margin-top: 0;
    }
    .segment_label{
        font-size: 12px;
    }
    .mainTitle{
        font-size: x-large;
        margin-bottom: 0;
    }
    .body-div{
        width: 90%;
        color: #196b69;
        /* text-align: start; */
        /* display: block; */
        /* justify-content: center;  */
      /* align-items: center;  */
        flex-direction: column;
        /* padding: auto; */
    }
    .plan_div{
        width: 80%;
        height: 50%;
        margin: 0px 20px 50px 20px;
    }
    .plan_item{
        font-size: 0.9em;
        color: #e4efe9;
        margin: 40px 5px 40px 15px;
    }

    
  }




@media(orientation: landscape){
    @media (max-height: 600px) {
        .premium_header{
            height: 12vh;
        }
    }
    @media (max-height: 400px) {
        .premium_header{
            height: 15vh;
        }
      }
  }

  @media(orientation: portrait){
    @media (max-width: 600px) {

        .premium_header{
            height: 7vh;
        }
    }
}