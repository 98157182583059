/* Card */
.p_recipe_card{
  border-radius: 20px;
  /* background-color: rgb(25,107,105); */
  margin-left: 10px;
  display: inline-block;
  width: 60%;
  height: 200px;
  /* width: inherit; */
  /* height: inherit; */
  margin: auto;
  transition: all .2s ease-in-out;
  /* margin-top: 80px; */
  box-shadow: 0px 0px 5px 0px rgba(73, 72, 72, 0.64);
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-image: url('../resources/goldbg5.jpg');
  background-size: cover;
  position: relative;
  /* margin-bottom: 20px; */
 
}



.p_image-container {
  position: absolute;
  top: -25px; /* Adjust as needed */
  right: -30px; /* Adjust as needed */
}
.p_image-container img {
  width: 60px; /* Adjust as needed */
  rotate: 35deg;
  /* margin-left: 10px; Adjust as needed */
}

.p_recipe_card:hover{
  transform: scale(1.05);
  box-shadow: 0px 0px 13px 0.5px rgba(0,0,0,0.36);
  box-shadow:
  inset 0 0 50px #fff,       /* inner white */
  inset 20px 0 80px #ffd700, /* inner left gold short */
  inset -20px 0 80px #ffd700,/* inner right gold short */
  inset 20px 0 300px #ffd700,/* inner left gold broad */
  inset -20px 0 300px #ffd700,/* inner right gold broad */
  0 0 100px #fff,            /* outer white with increased blur */
  -10px 0 50px #ffd700,     /* outer left gold with increased spread and blur */
  10px 0 50px #ffd700;      /* outer right gold with increased spread and blur */

}

.p_recipe_card:hover .p_recipe_card_header{
  color:black;
}

.p_recipe_card:hover .p_footer_icon{
  color:black;
}
.p_recipe_card:hover .p_footer_text{
  color:black;
}



/* Card Header */
.p_recipe_card_header{
  height: 10%;
  align-items: center;
  margin: auto;
  padding-top: 10%;
  font-size: 1em;
  color: white;
  font-weight: bold;
  font-family: "Calibri", sans-serif;
}

/* Card Image */
.p_recipe_card_image{
  width: 90%;
  display: flex;
  align-self: center;
  margin-top: -15%;
  margin-right: auto;
  margin-left: auto;
  transition: all .2s ease-in-out;
}
.p_recipe_card:hover > .p_recipe_card_image{
  transform: rotate(45deg); 
}

/* Card Footer */
.p_recipe_card_footer{
  border-radius: 20px;
  margin-top: 20%;
  height: 20%;
  width: 100%;
  display: flex;
}

/* Footer Buttons */
.p_footer_btn_left{
  flex: 1;
  border-radius: 0px 0px 0px 20px;
  vertical-align: middle;
  text-align: center;
  /* background-color: rgb(17, 75, 74); */
  /* border-right: 1px rgb(25,107,105) solid; */
}
.p_footer_btn_middle{
  flex: 1;
  vertical-align: middle;
  text-align: center;
  /* background-color: rgb(17, 75, 74); */
}
.p_footer_btn_right{
  flex: 1;
  border-radius: 0px 0px 20px;
  /* background-color: rgb(17, 75, 74); */
  vertical-align: middle;
  text-align: center;
  /* border-left: 1px rgb(25,107,105) solid; */
}
.p_footer_icon{
  font-size: 1em;
  color: white;
  padding-top: 10%;
  display: inline-block;
}
.p_footer_text{
  color: white;
  font-size: 0.6em;
}

@media(orientation: landscape){
  @media (max-height: 600px) {
    
  }
  @media (max-height: 400px) {
    
  }
}




@media only screen and (min-width:450px) and (max-width: 770px)  {
  .p_recipe_card{
      width: 70%;
      height: 250px;
      margin-top: 10px;
  }
  .p_recipe_card_footer{
    height: 20%;
    margin-top: 10%;
  }
}


@media only screen and (min-width: 870px) and (max-width: 1220px) {
  .p_recipe_card{
      width: 65%;
      /* height: 35vh; */
      height: 250px;
      margin-top: 10px;
      font-size: 13px;
  }
}

@media only screen and (min-width: 1220px){
  .p_recipe_card{
    width: 60%;
    /* height: 35vh; */
    height: 300px;
    margin-top: 10px;
    font-size: 20px;
}
}

@media only screen and (min-width: 1700px) {
  .p_recipe_card{
      width: 50%;
      /* height: 35vh; */
      height: 400px;
      margin-top: 10px;
      font-size: 20px;
  }
}

@media (orientation: portrait) {
  .p_recipe_card{
    width: 100%;
    height: 250px;
    margin-top: 10px;
  }
  .footer_text{
    font-size: 0.5em;
  }
  .footer_icon{
    font-size: 1.1em;
  }
}
