.privacy_header{
    height: 8%;
}

/* Responsive phone */
@media(orientation: landscape){
    @media (max-height: 600px) {
      .privacy_header{
          height: 12%;
      }
    }
    @media (max-height: 400px) {
      .privacy_header{
          height: 15%;
      }
    }
}

.privacy_content{
    --background: transparent !important;
    scrollbar-width: thin;
    scrollbar-color: #196b69 #f5f5f5;
    font-family: "Times New Roman", Times, serif;
}

.privacy_background{
    background-image: linear-gradient(45deg, #93cfbd 0%, #e4efe9 100%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    filter: blur(3px);
    z-index: 0;
    pointer-events: none; /* Prevent it from intercepting clicks or scrolls */ 
}

.privacy_background_img{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../resources/bg1.png');
    background-size: cover;
    filter: opacity(10%);
    z-index: -1;
}

.privacy_story{
    height: fit-content;
    color: #196b69;
    margin-bottom: 6%;
}

.privacy_story :is(li, p) {
    margin-left: 20px;
}

.privacy_main_title{
    text-align: start;
    font-family: 'Courier New', Courier, monospace;
    font-size: xx-large;
    margin-left: 1%;
    margin-top: 5%;
    font-weight: bold;
    color: #196b69;
}

.privacy_title{
    margin-left:1%;
    font-weight: bold;
    font-family: "Garamound", Times, serif;
}



