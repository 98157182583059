/* src/PaymentForm.css */

.payment-form {
    /* max-width: 50px; */
    margin: auto;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
}

/* label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
} */

input[type="number"],
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.card-element {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #0056b3;
}

.error {
    color: red;
    text-align: center;
    margin-top: 10px;
}

.success {
    color: green;
    text-align: center;
    margin-top: 10px;
}

.payment-div{
    width: 90%;
    margin: auto;
    margin-bottom: 10%;
    /* padding: 20px; */
    /* border-radius: 20px; */
    /* height: 80vh; */
}

.checkout{
    width: 20px;
    border-radius: 20px;
}