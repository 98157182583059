
.opinion_item{
  --background: transparent !important;
}

.opinion_input{
    width: 100%;
    margin-top: 2vh;
    margin-bottom: 2vh;
    --placeholder-color: #ffffff;
    --highlight-color: rgb(255, 255, 255) !important;
    --color: rgb(40,204,158);
    /* --color-focused: rgb(83, 0, 128); */
    --border-color-focused: green !important;
    --border-color: rgb(40,204,158) !important;
    /* text-align: right !important;
    direction: rtl !important; */
}

.opinion_textfield{
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  --placeholder-color: #ffffff;
  --highlight-color: rgb(255, 255, 255) !important;
  --color: rgb(40,204,158);
  /* --color-focused: rgb(83, 0, 128); */
  --border-color-focused: green !important;
  --border-color: rgb(40,204,158) !important;
  min-height: 40%;
}


.opinion_title{
  color: white;
  text-align: center;
  padding: 20px 0;
}

.opinion_btn{
  margin-top: auto;
  margin-bottom: 5%;
  --background: #28cc9e !important;
  background-color: #28cc9e !important;
  border-radius: 5px;
}


.form_container {
    padding: 20px;
    background: rgba(25,107,105,.8);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 50px auto; /* Center horizontally and add space from the top */
    /* transform: translateX(-180px); */
  }
  
  .input_field, .textarea_field {
    margin-bottom: 20px;
  }
  
  .textarea_field {
    height: auto;
  }
  
  /* .submit_button {
    margin-top: 20px;
    background-color: #3880ff;
    color: #fff;
  } */
  
  @media (max-width: 600px) {
    .form_container {
      padding: 16px;
      margin: 20px auto; /* Adjust top margin for smaller screens */
      transform: none;
      width: 80%;
    }
  }

  @media(orientation: portrait){
    @media (max-width: 600px) {
      .form_container {
        width: 95%;
      }
    
    }
}

  