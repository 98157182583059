/* Card */
.recipe_card{
  border-radius: 20px;
  background-color: rgb(25,107,105);
  /* margin-left: 10px; */
  display: inline-block;
  width: 100%;
  height: 300px;
  /* width: inherit; */
  /* height: inherit; */
  margin: auto;
  transition: all .2s ease-in-out;
  /* margin-top: 80px; */
  box-shadow: 0px 0px 5px 0px rgba(73, 72, 72, 0.64);
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
}
.recipe_card:hover{
  transform: scale(1.05);
  box-shadow: 0px 0px 13px 0.5px rgba(0,0,0,0.36);
}






/* Card Header */
.recipe_card_header{
  height: 10%;
  align-items: center;
  margin: auto;
  padding-top: 10%;
  font-size: 1em;
  color: rgb(255, 255, 255);
  /* font-weight: bold;
  font-family: "Calibri", sans-serif; */
}

/* Card Image */
.recipe_card_image{
  width: 90%;
  display: flex;
  align-self: center;
  margin-top: -15%;
  margin-right: auto;
  margin-left: auto;
  transition: all .2s ease-in-out;
}
.recipe_card_rot:hover > .recipe_card_image{
  transform: rotate(45deg); 
}

/* Card Footer */
.recipe_card_footer{
  border-radius: 20px;
  margin-top: 20%;
  height: 20%;
  width: 100%;
  display: flex;
}

/* Footer Buttons */
.footer_btn_left{
  flex: 1;
  border-radius: 0px 0px 0px 20px;
  vertical-align: middle;
  text-align: center;
  background-color: rgb(17, 75, 74);
  border-right: 1px rgb(25,107,105) solid;
}
.footer_btn_middle{
  flex: 1;
  vertical-align: middle;
  text-align: center;
  background-color: #114b4a;
}
.footer_btn_right{
  flex: 1;
  border-radius: 0px 0px 20px;
  background-color: rgb(17, 75, 74);
  vertical-align: middle;
  text-align: center;
  border-left: 1px rgb(25,107,105) solid;
}
.footer_icon{
  font-size: 1.2em;
  color: white;
  padding-top: 10%;
  display: inline-block;
}
.footer_text{
  color: white;
  font-size: 0.7em;
}


.recipe_image_container {
  position: absolute;
  top: -25px; /* Adjust as needed */
  right: -30px; /* Adjust as needed */
}
.recipe_image_container img {
  width: 60px; /* Adjust as needed */
  rotate: 35deg;
  /* margin-left: 10px; Adjust as needed */
}



@media (orientation: portrait) {
  .recipe_card{
    width: 100%;
    height: 250px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .footer_text{
    font-size: 0.5em;
  }
  .footer_icon{
    font-size: 1.1em;
  }
}





@media(orientation: portrait){
  @media (max-width: 600px) {

    .recipe_card_header_premium{
      padding-top:0 ;
    }
  }
}



@media only screen and (min-width:540px) and (max-width: 700px)  {
  .recipe_card{
      width: 70%;
      height: 300px;
      margin-top: 10px;
  }

  .recipe_card_footer{
    height: 20%;
    margin-top: 10%;
  }
}
/* 
@media only screen and (min-width:540px) and (max-width: 700px) and (max-height: 500px)  {
  .recipe_card{
      width: 70%;
      height: 70vh;
      margin-top: 10px;
  }
  .recipe_card_footer{
    height: 20%;
    margin-top: 10%;
  }
} */

/* @media only screen and (min-width:540px) and (max-width: 700px) and (max-height: 350px) and (false)  {
  .recipe_card{
      width: 70%;
      height: 80vh;
      height: 300px;
      margin-top: 10px;
  }
  .recipe_card_footer{
    height: 20%;
    margin-top: 10%;
  }
}
 */

@media only screen and (min-width: 1000px) and (max-width: 1220px) {
  .recipe_card{
      width: 100%;
      /* height: 35vh; */
      height: 250px;
      margin-top: 10px;
      font-size: 13px;
  }
}

@media only screen and (min-width: 1700px) {
  .recipe_card{
      width: 100%;
      /* height: 35vh; */
      height: 400px;
      margin-top: 10px;
      font-size: 20px;
  }
}



/* 

@media only screen and (max-width: 600px) {
  .recipe_card{
      width: 100%;
      height: 35vh;
      margin-top: 10px;
      
  } 
}


@media only screen and (min-width:600px) and (max-width: 700px)  {
  .recipe_card{
      width: 100%;
      height: 50vh;
      margin-top: 10px;
  }
}

@media only screen and (min-width:700px) and (max-width: 900px)  {
  .recipe_card{
      width: 100%;
      height: 40vh;
      margin-top: 10px;
  }
}


@media only screen and (min-width:700px) and (max-width: 900px) and (max-height: 600px) and (orientation: landscape)  {
  .recipe_card{
      width: 100%;
      height: 50vh;
      margin-top: 10px;
  }
}


@media only screen and (min-width:700px) and (max-width: 900px) and (max-height: 450px) and (orientation: landscape)  {
  .recipe_card{
      width: 100%;
      height: 70vh;
      margin-top: 10px;
  }
}
@media only screen and (min-width:700px) and (max-width: 900px) and (max-height: 350px)  {
  .recipe_card{
      width: 100%;
      height: 100vh;
      margin-top: 10px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1300px) {
  .recipe_card{
      width: 100%;
      height: 35vh;
      margin-top: 10px;
      font-size: 13px;
  }
}

@media only screen and (min-width: 370px) and (max-width: 700px) {
  .recipe_card{
    width: 100%;
    height: 100vh;
    margin-top: 10px;
      font-size: 13px;
  }
} */