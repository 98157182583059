.aboutus_header{
    height: 8%;
}

/* Responsive phone */
@media(orientation: landscape){
    @media (max-height: 600px) {
      .aboutus_header{
          height: 12%;
      }
    }
    @media (max-height: 400px) {
      .aboutus_header{
          height: 15%;
      }
    }
}

.aboutus_content{
    --background: transparent !important;
    scrollbar-width: thin;
    scrollbar-color: #196b69 #f5f5f5;
}

.aboutus_background{
    background-image: linear-gradient(45deg, #93cfbd 0%, #e4efe9 100%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    filter: blur(3px);
    z-index: 0;
    pointer-events: none; /* Prevent it from intercepting clicks or scrolls */
}

.aboutus_background_img{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../resources/bg1.png');
    background-size: cover;
    filter: opacity(10%);
    z-index: -1;
}

.aboutus_mainTitle{
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: xx-large;
    font-weight: bold;
    color: #196b69;
}

.aboutus_body_div{
    margin: auto;
    width: 50%;
    color: #196b69;
    text-align: start;
}

.aboutus_titles{
    text-align: start;
    color: #196b69;
    margin-left: 2%;
    font-size: 2em;
    font-weight: bold;
    font-family: "Garamound", Times, serif;
    margin-top: 7%;
}

.aboutus h5{
    line-height: 1;
    margin-left: 20px;
    font-family: "Times New Roman", Times, serif;
}

/* responsive phone */
@media only screen and (max-width: 700px) {
    .aboutus_body_div{
        width: 95%;
    }
    .aboutus_titles{
        font-size: 1.5em;
    }
    .aboutus h5{
        font-size: 1em;
    }
}