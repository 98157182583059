.home_header{
    height: 8%;
}

@media(orientation: landscape){
  @media (max-height: 600px) {
    .home_header{
        height: 12vh;
    }
  }
  @media (max-height: 400px) {
    .home_header{
        height: 15vh;
    }
  }
}

.logo{
    height: 100%;
    width: 13%;
    display: flex;
    justify-content: left;
}

/* .swiper{
    
    width: 100%;
    max-height: 30%;
} */

@media (orientation: landscape) {
    .swiper{
        width: 100%;
        height: auto;
        /* max-height: 70%; */
    }
}

@media (orientation: portrait) {
    /* .swiper{
        width: 140%;
        height: auto;
        object-fit: contain;
    } */
    .swiper-container{
      /* width: 140% !important; */
    }
    .swiper-container .swiper-zoom-container>img {
      width: 140% !important;
      height: auto;
      object-fit: scale-down;
      /* object-position: center; */
  }
}

.slide{

}
.slideImg{

}

.swiper{
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.swiper-container .swiper-zoom-container>img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
}



.card_div{
    /* background-color: red; */
    height: 100x;
    width: 200px;
    margin: 30px;
}

.card_img{
    /* margin-top: -30px; */
}

.home_background{
    

    background-image: linear-gradient(45deg, #93cfbd 0%, #e4efe9 100%);


    position: fixed;
    top: 0;
    left: 0;
    /* right: 0;
    bottom: 0; */
    width: 100vw;
    height: 100vh;
    filter: blur(3px);
    z-index: 0;
    pointer-events: none; /* Prevent it from intercepting clicks or scrolls */

    
}


.home_background_img{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* filter: blur(3px); */
    background-image: url('../resources/bg1.png');
    background-size: cover;
    filter: opacity(30%);
    z-index: -1;
}

/* ::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: rgb(255, 255, 255);
}
::-webkit-scrollbar-thumb:horizontal{
    background: #787878;
    border-radius: 10px;
} */



.categories_div{
    margin-top: 2vw;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    /* background-color: red; */
    padding: 2vh;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.home_fav_btn{
    margin: auto 0 auto 0;
    height: 43px;
    --background: #88c0af;
    --background-activated: rgb(25,107,105);
    --background-focused:  rgb(25,107,105);
    --background-hover: #196b69;
    /* margin: 2vh 3vw; */

}


.home_fav_btn_clicked{
  margin: auto 0 auto 0;
    height: 43px;
    --background: rgb(25,107,105);
    --background-activated: rgb(25,107,105);
    --background-focused:  rgb(25,107,105);
    --background-hover: rgb(25,107,105);
    /* margin: 1vh 3vw; */

}

.searched{
    /* height: 50%; */
    /* margin: auto; */
    height: inherit;
    width: fit-content;
    --background: rgb(25,107,105);
    margin: 0 20px;
}


.search{
  /* height: 50%; */
    /* margin: auto; */
    height: inherit;
    width: fit-content;
    --background: #88c0af;
    margin: 0 20px;
    --color: white;

}

.home_category_selected{
  min-height: 43px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  /* height: 20px !important; */
  height: 10px;
  background-color:#196b69  ;
  margin: auto 0 auto 0;
}



.home_category_select{
  min-height: 43px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  /* height: 20px !important; */
  height: 10px;
  background-color:#88c0af;
  --color: white !important;
  margin: auto 0 auto 0;
}

.home_category_select ion-label{
  --color: green !important;
}

.home_category_select .select-interface {
  height: 5px !important;
}

.home_category_select {
  :global .Select-control {
      height: 30px;
  }
  :global .Select-placeholder {
      line-height: 20px
  }
  :global .Select-value {
    line-height: 26px !important;
  }

  :global .Select-value-label {
      line-height: 26px;
    }

  :global .Select-input {
    height: 26px;
  }
}


.home_category_item{
  /* height: 20px; */
}
@media (orientation: portrait) {
  .search{
    margin: auto;
    width: 85%;
  }
  .searched{
    margin: auto;
    width: 85%;
  }
  .home_fav_btn{
    margin: auto;
    width: 30%;
  }

  .home_fav_btn_clicked{
    margin: auto;
    width: 30%;
  }

  .home_category_select{
    margin: auto;
  }
  .home_category_selected{
    margin: auto;
  }
  .swiper-button-next{
    display: none;
  }
  
  .swiper-button-prev{
    display: none;
  }
  
}




.lister_col{
    /* opacity: 0; */
    /* width: fit-content; */
    /* height: fit-content; */
    /* --background-color: brown;
     */
     /* background-color: blue; */
     margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 3%;
    /* border: solid black 1px; */
}

.lister_row{
    margin: auto;
    /* background-color : green; */
}

.lister_grid{
    flex: 1;
    margin-left: auto;
    width: 50%;
    /* min-height: 100vh; */
    margin: auto;
}



.home_recipe_body{
  display: flex;
  width: 100%;
  height: max-content;
}

.home_ads{
  position: sticky;
  /* flex: 1; */
  width: 25%;
  height: 20%;
  display: block;
  top: 0;
}

.home_ads_mobile{
  position: sticky;
  /* flex: 1; */
  width: 100vw 
  
  ;
  /* min-width: 100%; */
  height: 20%;
  /* display: block;s */
  top: 0;
}

.ad_item{
  position: static;
  width: 100%;
  /* background-color: red; */
  height: 30vh;
  margin-top: 8vh;
}


.recent_recipes{
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vw;
    height: fit-content;
    /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
    padding-bottom: 2vh;
    border-radius: 20px;
    /* background-image: url('../resources/bg1.png');
    background-size: contain; */
   
    /* background-color: red; */
    /* height: 30%; */
    /* overflow: scroll;
     */

}


.recent_title{
    margin: auto;

    width: 80%;
    height: fit-content;
    margin-top: 5%;
    background: rgba(192, 184, 67, 0.5);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3.5px );
    -webkit-backdrop-filter: blur( 3.5px );
    border-radius: 10px 10px 0 0;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    position: relative;
    text-align: center;

    color: #000000;
    /* background-color: #bababa; */
    font-size: 1.9em;
    font-family: 'fantasy';
    font-style: italic;

    box-shadow: 0px 0px 13px 0.5px rgba(0,0,0,0.36);
  box-shadow:
  inset 0 0 50px #fff,       /* inner white */
  inset 20px 0 80px #ffd700, /* inner left gold short */
  inset -20px 0 80px #ffd700,/* inner right gold short */
  inset 20px 0 300px #ffd700,/* inner left gold broad */
  inset -20px 0 300px #ffd700,/* inner right gold broad */
  0 0 100px #fff,            /* outer white with increased blur */
  -10px 0 50px #ffd700,     /* outer left gold with increased spread and blur */
  10px 0 50px #ffd700;      /* outer right gold with increased spread and blur */
    /* font-weight: 700; */
    /* text-shadow: #e0e0e0 1px 1px 0; */
}

.recent_title_title{
    width: fit-content;
    /* background-color: red; */
    height: 5%  ;
    margin: auto;
    text-align: center;
    /* float: left; */
    /* width: 100px; */
}

.recent{
    margin: auto;
    width: 80%;
    height: fit-content;
    /* margin-top: 2%; */
    background: rgba(192, 184, 67, 0.5);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3.5px );
    -webkit-backdrop-filter: blur( 3.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    position: relative;
}

.recent_bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../resources/pbg3.jpg');
    opacity: 20%;
    background-size: 100%;
}

.prem{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    width: fit-content;
    display: inline-block;
    rotate: -40deg;
    font-size: 7em;
    /* background-color: red; */
    margin-top: 10%;
}


.recent_title{
    /* margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px; */
}

.recent_txt{
    /* margin-left: 20px; */
}

.recent_icon{
    margin-left: 40px;
}

.recent_line{
    width: 80%;
    height: 1px; 
    background: rgb(0, 0, 0);
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
}








.recent_container{
    
    /* position: absolute; */
    height: 100%;
    width: 100% ;
    opacity: 20%;
    /* background-color: red; */
    /* width: 100%; */
    /* height: 100vh; */
    overflow: hidden;
}

.recent_container img {
    position: absolute;
    width: 100px;
    opacity: 0;
    
    animation: movePicture 10s linear infinite;
}


@keyframes movePicture {
    0% {
        transform: translateY(100%) translateX(-50%);
        opacity: 0;
      }
      5% {
        opacity: 1;
      }
      95% {
        opacity: 1;
      }
      100% {
        transform: translateY(-100%) translateX(50%);
        opacity: 0;
      }
  }


  .recent_container img:nth-child(1) {
    position: absolute;
    top: 50;
    left: 100;
  }
  
  .recent_container img:nth-child(2) {
    top: 0;
    right: 0;
  }
  
  .recent_container img:nth-child(3) {
    bottom: 0;
    left: 0;
  }
  
  .recent_container img:nth-child(4) {
    bottom: 0;
    right: 0;
  }

.border{
    position: absolute;
    rotate: 90deg;
    height: 100%;
    width: 2%;
    background-size: 100%;
    
    /* background-color: red; */
    /* background-image: url('../resources/border.png'); */
    left: 100;
    top: -50;
    
}
.recent_bg{
    overflow: visible;
}

  .recent_bg div:nth-child(1){
    left: 0;
    top: 0;
  }



  .home_content {
    --background: transparent !important;
    min-height: 80vh;
  }









  .home_content{
    /* --offset-bottom: auto !important; */
    /* --overflow-y: auto !important; */
    overflow-y: auto !important;
     overflow-x: hidden !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-right: 100px !important; */
    /* width: 80% !important; */
    box-sizing: border-box; /* Ensure padding doesn’t expand content */
  }

  .Page{
    width: 100vw;
    min-height: 100vh;
    /* --overflow: hidden !important;
    overflow-x: hidden !important; */
  }
  


  .home_no_recipe{
    
    margin: auto;
    /* margin-top: 5%; */
    color: #196b69;
    font-size: 1.5em;

  }


  .home_content {
    /* Make sure it's scrollable */
    overflow-y: auto;
}



/* Firefox */
.home_content {
  scrollbar-width: thin; /* Make the scrollbar thinner */
  scrollbar-color: #196b69 #f5f5f5; /* Thumb color and track color */
}

/* Chrome, Edge, and Safari */
.home_content::-webkit-scrollbar {
  width: 50px; /* Scrollbar width */
}

.home_content::-webkit-scrollbar-track {
  --background: #ff0505; /* Track color */
}

.home_content::-webkit-scrollbar-thumb {
  background-color: #196b69; /* Thumb color */
  --border-radius: 10px !important; /* Rounded corners */
  border: 2px solid #f5f5f5; /* Optional border to match track */
}

.home_content::-webkit-scrollbar-thumb:hover {
  background-color: #2bd3ca; /* Darker color when hovering */
}


.loading{
  width: 100%;
  /* height: 50vh; */
  /* display: flex; */
  /* align-items: center;
  justify-content: center;
  vertical-align: middle; */
  margin: auto;
  text-align: center;
  color: #196b69;
  /* background-color: red; */
  font-size: larger;
}

.spinner{
  margin: auto;
  margin-left: 5px;
  margin-top: 5px;
  --color: #196b69;
}

.searchbar-input-container{
  /* height: 20px; */
}

  
@media(orientation: portrait){
  @media(max-width: 400px){
    .home_recipe_body{
      min-height: 40vh;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
    .lister_grid{
      width: 100%;
      min-height: 0;
      height: 100%;
      margin: 0;
      padding: 0;
      /* max-width: 400px; */
    }
    .recent{
      width: 95%;
    }
    .recent_title{
      width: 95%;
      font-size: 1.3em;
    }
  }

}


/* @media(orientation: landscape){
  @media (max-height: 600px) {
      .premium_header{
          height: 20%;
      }
  }
} */
@media(orientation: portrait){
  @media (max-width: 600px) {
      .home_header{
          height: 7vh;
      }
  }
}

.home_alert .alert-wrapper {
  background-color: #196b69;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.268);
}
.home_alert .alert-wrapper .alert-message {
  color: white;
}
.home_alert_arabic .alert-wrapper .alert-message {
  direction: rtl !important;
}
.home_alert .alert-wrapper .alert-head { 
  background: #114b4a;
  margin-bottom: 15px
}

.home_alert .alert-wrapper .alert-head .alert-title{
  color: rgb(255, 255, 255) !important;
}

ion-backdrop{
  opacity: 0.75 !important;
  background-color: rgb(0, 0, 0);  /* Optional: Semi-transparent background */
}

.home_alert .alert-button {
  border-radius: 10px;
  min-width: 40%;
  width: fit-content;
  /* text-align: center; */
  margin: auto;
  background-color: #28cc9e;
  color: white;
  
}

.home_alert .alert-button-inner{
  /* color: red;
  background-color: red; */
  text-align: center !important;
  justify-content: center !important;
}

@media(orientation: portrait){
  @media(max-width: 400px){
    .recent{
      /* height: 100%; */
      padding: 0;
    }
    .recent_recipes{
      height: 100%;
      margin: 0;
      padding: 0;
    }
    .lister_row_recent{
      margin: 0;
      height: 100%;
      display: flex;          /* Use flex layout */
      flex-wrap: nowrap;      /* Prevent wrapping to the next line */
      overflow-x: auto;       /* Enable horizontal scrolling */
      padding: 10px;
      padding-top: 20px;
      padding-bottom: 20px;          
      scrollbar-width: none;
    }
    .lister_row_recent::-webkit-scrollbar {
      display: none;
    }
  } 
}


@media(max-width:700){
  .home_category_select{
    margin: auto;
  }
  .home_category_selected{
    margin: auto;
  }
  .home_fav_btn{
    margin: auto !important;
  }
  .home_fav_btn_clicked{
    margin: auto;
  }
}
